@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu/Ubuntu-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu-Bold';
    src: url('./assets/fonts/Ubuntu/Ubuntu-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}