.app-background {
    background-image: url('./assets/images/automa.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.my-masonry-grid {
    display: flex;
    margin-left: -15px;
    /* Adjusted gutter size */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 15px;
    /* Adjusted gutter size */
    background-clip: padding-box;
}

.my-masonry-grid_column>div {
    margin-bottom: 15px;
    /* Adjusted bottom margin */
}

/* Optional media query styles */
@media (max-width: 1100px) {
    .my-masonry-grid {
        margin-left: -10px;
    }

    .my-masonry-grid_column {
        padding-left: 10px;
    }
}

@media (max-width: 700px) {
    .my-masonry-grid {
        margin-left: -5px;
    }

    .my-masonry-grid_column {
        padding-left: 5px;
    }
}

@media (max-width: 500px) {
    .my-masonry-grid {
        margin-left: -5px;
    }

    .my-masonry-grid_column {
        padding-left: 5px;
    }
}